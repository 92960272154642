<script lang="ts">
  import { truncateString } from 'common/src/utils'
  import { type DeskContent } from 'common/src/types'

  import BaseRenderer from '../cell-renderer.svelte'
  import { cleanRowStatus } from '../cell-renderer'

  import RowCallToAction from '../row-call-to-action/row-call-to-action.svelte'

  export let key: string
  export let value: any
  export let autoWidth: boolean
  export let rowNumber: string
  export let rowStatus: string
  export let selectedId: string
  export let content: DeskContent
  export let ctaTop: boolean

  let elem: HTMLElement

  $: {
    cleanRowStatus(elem as HTMLElement, rowStatus)
  }
</script>

{#if key === 'id'}
  <div bind:this={elem}>
    <RowCallToAction {key} {content} {ctaTop} />
  </div>
{:else if key === 'text'}
  {truncateString(value)}
{:else}
  <BaseRenderer {key} {value} {rowNumber} {autoWidth} {rowStatus} {content} {selectedId} />
{/if}
